import axios from "axios";
import { API_URLS, BASE_URL } from "../../config";

function getWelcomeMessageApi(id) {
  return axios.get(`${BASE_URL}?id=${id}`);
}

function getPromptResponseApi(payload, userId, id, file) {
  const formData = new FormData();
  formData.append("file", file);
  if (file) {
    return axios.post(
      `${API_URLS.CHAT.PROMPT_IMAGE_RESPONSE}?user_id=${userId}&id=${id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } else {
    return axios.post(
      `${API_URLS.CHAT.PROMPT_RESPONSE}?user_query=${payload}&user_id=${userId}&id=${id}`
      // formData,
      //  {
      //    headers: {
      //      "Content-Type": "multipart/form-data",
      //    },
      //  }
    );
  }
}

function getAudioFromTextApi(text, id) {
  return axios.post(`${API_URLS.CHAT.AUDIO_FROM_TEXT}?text=${text}&id=${id}`);
}

function saveUserChatApi(chatName, user_id) {
  return axios.post(
    `${API_URLS.CHAT.SAVE_USER_CHAT}?user_id=${user_id}&save_name=${chatName}`
  );
}

function getChatHistoryApi(userId) {
  return axios.post(`${API_URLS.CHAT.GET_CHAT_HISTORY}?user_id=${userId}`);
}

export {
  getWelcomeMessageApi,
  getPromptResponseApi,
  saveUserChatApi,
  getChatHistoryApi,
  getAudioFromTextApi,
};
