import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BsEye,
  BsFillMicFill,
  BsFillMicMuteFill,
  BsFillStopFill,
} from "react-icons/bs";
import { CiCircleRemove } from "react-icons/ci";
import { RiImageAddFill } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineCloudUpload } from "react-icons/md";
import { useNavigate } from "react-router";
import Loader from "react-dots-loader";

import {
  Box,
  Button,
  ConfirmationModal,
  GenericInput,
  GenericModal,
  Toaster,
  Typography,
} from "../../Components";
import {
  getAudioFromText,
  getPromptResponse,
  getUserChatHistory,
  getWelcomeMessage,
  saveUserChat,
} from "../../Redux/actions";
import { BASE_URL, ROUTER_PATH } from "../../config";
import ReplyTemplate from "./components/ReplyTemplate";
import MessageTemplate from "./components/MessageTemplate";
import {
  Col,
  Form,
  Overlay,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { IMAGES } from "../../Assets";
import ReactPlayer from "react-player";
import { useLocation } from "react-router-dom";
// import { Unity, useUnityContext } from "react-unity-webgl";
import { ReactMic } from "react-mic";
import axios from "axios";
import { getAudioFromTextApi } from "../../Redux/api/chat";
import useWindowSizeHook from "../../Components/WindowSizeHook";

export default function PatientDiagnosis() {
  const [file, setFile] = useState();
  const [previewImg, setPreviewImg] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  // const [previewImage, setPreviewImage] = useState("");
  const [showEye, setShowEye] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const inActiveTimer = useRef(null);
  const inputRef = useRef(null);
  const [inActiveModal, setInActiveModal] = useState(false);
  const { state } = useLocation();
  const [textareaValue, setTextareaValue] = useState("");
  const [chatArray, setChatArray] = useState([]);
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);
  const [chatName, setChatName] = useState();
  const [unityLoaded, setUnityLoaded] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [sentVoice, setSentVoice] = useState(false);
  const [isPermissionGranted, setIsPermissionGranted] = useState(false);
  const playerRef = useRef();
  console.log("file", file);
  const USER_ID = localStorage.getItem("userId");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const chat = useSelector((state) => state.chat);
  console.log("USER_ID", USER_ID);
  function startInActiveTimer() {
    inActiveTimer.current = setTimeout(() => {
      clearTimeout(inActiveTimer.current);
      setInActiveModal(true);
      // }, 10000);
    }, 600000);
  }

  function resetInActiveTimer() {
    clearTimeout(inActiveTimer.current);
    startInActiveTimer();
  }

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
    resetInActiveTimer();
  };

  const sendPrompt = (messageValue) => {
    resetInActiveTimer();
    //
    const prompt = {
      id: Date.now() + Math.random(),
      // type: `${file ? "imagePrompt" : "prompt"}`,
      type: "prompt",

      message: messageValue,
      // file: previewImg,
    };
    setChatArray((prevChatArray) => [...prevChatArray, prompt]);
    setPreviewImg(null);
    setTextareaValue("");
    dispatch(
      getPromptResponse(
        messageValue,
        USER_ID,
        state?.isGender === "male" ? 0 : 1
        // file
      )
    );
  };
  const sendImagePrompt = ({ file, messageValue }) => {
    resetInActiveTimer();
    //
    const prompt = {
      id: Date.now() + Math.random(),
      type: `${file ? "imagePrompt" : "prompt"}`,
      message: "what should i do about this",
      file: URL.createObjectURL(file),
    };
    setChatArray((prevChatArray) => [...prevChatArray, prompt]);
    setPreviewImg(null);
    setTextareaValue("");
    dispatch(
      getPromptResponse(
        messageValue,
        USER_ID,
        state?.isGender === "male" ? 0 : 1,
        file
      )
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      sendPrompt(textareaValue);
    }
  };
  const handleSaveChat = () => {
    dispatch(saveUserChat(chatName, USER_ID, Toaster, moveToNext));
  };
  const moveToNext = () => {
    setConfirmationModalShow(false);
    dispatch(getUserChatHistory(USER_ID));
    setChatArray([]);
  };
  const scrollFunction = () => {
    const scroller = document.querySelector("#chatBox");
    scroller.scroll(0, 10000);
  };

  useEffect(() => {
    if (state?.isGender === undefined) {
      navigate(ROUTER_PATH.HOME_PAGE);
    }
    scrollFunction();
  }, [chatArray]);

  useEffect(() => {
    //
    // if (state?.isGender !== null && unityLoaded) {
    if (state?.isGender !== null) {
      dispatch(getWelcomeMessage({ id: state?.isGender === "male" ? 0 : 1 }));
    }
    // }, [dispatch, state, unityLoaded]);
  }, [dispatch, state]);

  const getAudio = async (text, id) => {
    // dispatch(getAudioFromText());
    const response = await getAudioFromTextApi(text, id);

    // let bytes = new Uint8Array(response.data.length);

    // for (let i = 0; i < bytes.length; i++) {
    //   bytes[i] = response.data.charCodeAt(i);
    // }
    //
    // let blob = new Blob([bytes], { type: "audio/mp3" });

    // const blob = await response.data.blob();
    // const audioDataUrl = URL.createObjectURL(blob);

    // const audioBase64 = await response.data.text();
    const audioDataUrl = `data:audio/mp3;base64,${response.data}`;

    // setTimeout(() => {
    setVideoPlaying(true);
    resetInActiveTimer();
    // }, 300);

    setAudioBlob(audioDataUrl);

    // setAudioBlob(blob);
  };

  // useEffect(() => {
  //   if (audioBlob) {
  //     setVideoPlaying(true);
  //   }

  //   return () => {
  //     setVideoPlaying(false);
  //   };
  // }, [audioBlob]);

  useEffect(() => {
    // if (unityLoaded) {
    let reply = {
      id: Date.now() + Math.random(),
      type: "reply",
      reply: chat?.WELCOME_MESSAGE,
    };
    setChatArray([reply]);
    startInActiveTimer();
    // }
    // }, [chat?.WELCOME_MESSAGE, unityLoaded]);
  }, [chat?.WELCOME_MESSAGE]);

  useEffect(() => {
    let timeoutId;
    debugger;
    if (chat?.PROMPT_RESPONSE?.generated_text) {
      let reply = {
        id: Date.now() + Math.random(),
        type: "reply",
        reply: chat?.PROMPT_RESPONSE?.generated_text,
      };
      setChatArray([...chatArray, reply]);
      // resetInActiveTimer();
      // setFile(null);
    } else if (chat?.PROMPT_RESPONSE?.data) {
      debugger;
      let reply = {
        id: Date.now() + Math.random(),
        type: "reply",
        reply: chat?.PROMPT_RESPONSE?.data,
      };
      setChatArray((prev) => [...prev, reply]);
      timeoutId = setTimeout(() => {
        sendPrompt("what should i do about this");
      }, 1000);

      // const messageValue = "what should i do about this ";
      // dispatch(
      //   getPromptResponse(
      //     messageValue,
      //     USER_ID,
      //     state?.isGender === "male" ? 0 : 1
      //     // file
      //   )
      // );
    }
    return () => {
      clearTimeout(timeoutId);
    };

    // eslint-disable-next-line
  }, [chat?.PROMPT_RESPONSE?.generated_text, chat?.PROMPT_RESPONSE?.data]);

  const [spokenMessages, setSpokenMessages] = useState([]);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [startListening, setStartListening] = useState(false);
  // const [voices, setVoices] = useState([]);

  // Function to preload voices and update the state when they are available
  // const loadVoices = () => {
  //   return new Promise((resolve) => {
  //     const voicesInterval = setInterval(() => {
  //       const loadedVoices = speechSynthesis.getVoices();

  //       if (loadedVoices.length) {
  //         clearInterval(voicesInterval);
  //         setVoices(loadedVoices);
  //         resolve();
  //       }
  //     }, 100);
  //   });
  // };

  // const getVoiceFromAPI = async () => {
  //   const response = await axios.get("/api/voices");

  //   return response
  // };

  // Usage in an async function:
  // Now you can use voices for speech synthesis

  // const speakMessage = async (message, gender) => {
  //   await loadVoices(); // Preload voices before speaking
  //   if (message !== null && gender !== null) {
  //     const maxChunkLength = 200; // Maximum chunk length
  //     if (message.length <= maxChunkLength) {
  //       // If the message is shorter than the maximum chunk length, speak it as is
  //       const utterance = new SpeechSynthesisUtterance(message);
  //       setVideoPlaying(true);
  //       // setTimeout(() => {
  //       //   sendMessage("CharacterManager", "EnableMotionEventCall");
  //       // }, 1000);

  //       console.log("OnEnd Outside Called");

  //       utterance.onend = () => {
  //         console.log("OnEnd Called");
  //         setVideoPlaying(false);
  //         resetInActiveTimer();
  //         // sendMessage("CharacterManager", "DisableMotionEventCall");
  //       };

  //       // Set the voice based on the specified gender
  //       if (gender === "male") {
  //         // Find a male English UK voice
  //         const maleUKVoice = voices.find((voice) => {
  //           return voice.lang === "en-GB" && voice.name.includes("Male");
  //         });
  //         if (maleUKVoice) {
  //           utterance.voice = maleUKVoice;
  //         } else {
  //           console.error("No male English UK voice available.");
  //         }
  //       } else if (gender === "female") {
  //         // Find a female English UK voice
  //         const femaleUKVoice = voices.find((voice) => {
  //           if (voice.lang === "en-GB") {
  //             return (
  //               // (voice.name.includes("Female") || voice.name.includes("Tessa"))
  //               voice.name.includes("Female")
  //             );
  //           } else if (voice.lang === "en-US") {
  //             return (
  //               // (voice.lang === "en-GB" || voice.lang === "en-US") &&
  //               // (voice.name.includes("Female") || voice.name.includes("Tessa"))

  //               voice.name.includes("Female") || voice.name.includes("Samantha")
  //             );
  //           } else {
  //             return false;
  //           }
  //         });

  //         if (femaleUKVoice) {
  //           utterance.voice = femaleUKVoice;
  //         } else {
  //           console.error("No female English UK voice available.");
  //         }
  //       } else {
  //         console.error("Invalid gender specified.");
  //       }

  //       utterance.lang = "en-GB"; // Set the voice to English UK accent
  //       speechSynthesis.speak(utterance);
  //     } else {
  //       // If the message is longer than the maximum chunk length, split it into chunks
  //       const chunks = [];
  //       for (let i = 0; i < message.length; i += maxChunkLength) {
  //         chunks.push(message.substring(i, i + maxChunkLength));
  //       }

  //       // Speak each chunk sequentially
  //       for (const chunk of chunks) {
  //         await new Promise((resolve) => {
  //           const utterance = new SpeechSynthesisUtterance(chunk);
  //           setVideoPlaying(true);
  //           utterance.addEventListener("end", function () {
  //             setVideoPlaying(false);
  //             console.log("Speech synthesis completed.");
  //           });
  //           // setTimeout(() => {
  //           //   sendMessage("CharacterManager", "EnableMotionEventCall");
  //           // }, 1000);
  //           console.log("OnEnd Outside Called");

  //           utterance.onend = () => {
  //             console.log("OnEnd Called");

  //             setVideoPlaying(false);
  //             // sendMessage("CharacterManager", "DisableMotionEventCall");
  //             resolve();
  //           };

  //           // Set the voice based on the specified gender
  //           if (gender === "male") {
  //             // Find a male English UK voice
  //             const maleUKVoice = voices.find((voice) => {
  //               return voice.lang === "en-GB" && voice.name.includes("Male");
  //             });
  //             if (maleUKVoice) {
  //               utterance.voice = maleUKVoice;
  //             } else {
  //               console.error("No male English UK voice available.");
  //             }
  //           } else if (gender === "female") {
  //             // Find a female English UK voice
  //             const femaleUKVoice = voices.find((voice) => {
  //               return voice.name.includes("Female");
  //               // return voice.lang === "en-GB" && voice.name.includes("Female");
  //             });
  //             if (femaleUKVoice) {
  //               utterance.voice = femaleUKVoice;
  //             } else {
  //               console.error("No female English UK voice available.");
  //             }
  //           } else {
  //             console.error("Invalid gender specified.");
  //           }

  //           utterance.lang = "en-GB"; // Set the voice to English UK accent
  //           speechSynthesis.speak(utterance);
  //         });
  //       }
  //     }
  //   }
  // };

  // Function to handle "reply" messages
  const handleReplyMessage = (item) => {
    if (item.type === "reply" && !spokenMessages.includes(item.id)) {
      // Update gender value according to data
      if (state?.isGender !== null && item.reply !== null) {
        // speakMessage(item.reply, state?.isGender);
        getAudio(item.reply, state?.isGender === "female" ? 1 : 0);
        resetInActiveTimer();
      }
      setSpokenMessages((prevMessages) => [...prevMessages, item.id]);
    }
  };

  useEffect(() => {
    // Speak "reply" messages when the component
    chatArray.forEach(handleReplyMessage);
  }, [chatArray, state?.isGender]);

  let mediaRecorder = null;
  let recordingStart = false;
  let audioStream = null;
  const timerRef = useRef(null);
  const MIN_DECIBELS = -40;

  const stopRecordingVoice = () => {
    mediaRecorder?.stop();
    audioStream.getTracks().forEach((track) => track.stop());
    recordingStart = false;
    setStartListening(false);
  };
  const startTimeCount = () => {
    timerRef.current = setTimeout(() => {
      // console.log("no voice");
      clearTimeout(timerRef.current);
      stopRecordingVoice();
      // console.log("recording stop");
    }, 5000);
  };
  function handleResetTimer() {
    // console.log("reseting");
    clearTimeout(timerRef.current);
    startTimeCount();
  }

  const startVoiceRecording = async () => {
    resetInActiveTimer();
    audioStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    mediaRecorder = new MediaRecorder(audioStream);
    mediaRecorder.start();

    recordingStart = true;
    // console.log("recording start");
    startTimeCount();
    const audioChunks = [];
    mediaRecorder.addEventListener("dataavailable", (event) => {
      audioChunks.push(event.data);

      resetInActiveTimer();
    });

    const audioContext = new AudioContext();
    const audioStreamSource = audioContext.createMediaStreamSource(audioStream);
    const analyser = audioContext.createAnalyser();
    analyser.minDecibels = MIN_DECIBELS;
    audioStreamSource.connect(analyser);

    const bufferLength = analyser.frequencyBinCount;
    const domainData = new Uint8Array(bufferLength);

    let soundDetected = false;

    const detectSound = () => {
      if (!recordingStart) {
        // console.log("not recording");
        return;
      }

      analyser.getByteFrequencyData(domainData);

      for (let i = 0; i < bufferLength; i++) {
        const value = domainData[i];

        if (domainData[i] > 0) {
          // console.log("sound detected");
          soundDetected = true;
          handleResetTimer();
        }
      }

      window.requestAnimationFrame(detectSound);
    };

    window.requestAnimationFrame(detectSound);

    mediaRecorder.addEventListener("stop", async () => {
      if (soundDetected) {
        const audioBlob = new Blob(audioChunks, {
          type: "audio/wav",
        });
        sendAudioToAPI(audioBlob);
      }
      // console.log("soundDetected", soundDetected);
      resetInActiveTimer();
    });
  };
  const checkAndRequestMicrophonePermission = async (startListen = false) => {
    resetInActiveTimer();
    try {
      // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setIsPermissionGranted(true);

      // audioStream?.getTracks().forEach((track) => track.stop());
      if (startListen) {
        setStartListening(true);
      }
    } catch (error) {
      setIsPermissionGranted(false);
    }
  };

  // const checkMicrophonePermission = async () => {
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  //     setIsPermissionGranted(true);
  //     stream.getTracks().forEach((track) => track.stop());
  //   } catch (error) {
  //     setIsPermissionGranted(false);
  //   }
  // };

  // Add the window event listener for beforeunload
  useEffect(() => {
    // Add the window event listener for beforeunload
    const handleBeforeUnload = () => {
      if (speechSynthesis.speaking) {
        speechSynthesis.cancel();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    // checkAndRequestMicrophonePermission();
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);

      // Stop speech synthesis when the component unmounts
      if (speechSynthesis.speaking) {
        speechSynthesis.cancel();
      }
    };
  }, []);

  function isPlaying(audelem) {
    return !audelem.paused;
  }

  // const { unityProvider, sendMessage, unload, isLoaded } = new useUnityContext({
  //   loaderUrl: "salsaBuild/lipsync.loader.js",
  //   dataUrl: "salsaBuild/lipsync.data",
  //   frameworkUrl: "salsaBuild/lipsync.framework.js",
  //   codeUrl: "salsaBuild/lipsync.wasm",
  // });

  // useEffect(() => {
  //   if (isLoaded) {
  //     setTimeout(() => {
  //       setUnityLoaded(isLoaded);
  //     }, 2000);
  //     sendMessage(
  //       "Canvas",
  //       "CharacterSelected",
  //       state?.isGender === "male" ? 0 : 1
  //     );
  //     sendMessage("Canvas", "LoadNextScene");
  //   }
  // }, [isLoaded]);
  // console.log("unityLoaded", unityLoaded);
  // console.log("state 2", state);

  // const onData = (recordedBlob) => {
  //   // This function will be called when data is available during recording.
  //   // You can use it to update the audioBlob state.
  //   setAudioBlob(recordedBlob.blob);
  // };

  const sendAudioToAPI = async (audioData) => {
    try {
      const formData = new FormData();
      formData.append("audio_file", audioData, "recorded-audio.wav");
      const response = await axios.post(
        BASE_URL + "/speech_to_text",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      sendPrompt(response.data.response);
      // setSentVoice(false);
      // setAudioBlob(null);
    } catch (error) {
      console.error("Error sending audio to API:", error);
    }
  };

  // useEffect(() => {
  //   if (audioBlob && sentVoice) {
  //     sendAudioToAPI();
  //   }
  // }, [audioBlob, sentVoice]);

  const startVoiceCommand = () => {
    resetInActiveTimer();
    if (!videoPlaying) {
      if (isPermissionGranted) {
        setStartListening(true);
      } else {
        // Request microphone permission
        checkAndRequestMicrophonePermission(true);
      }
    }
  };

  useEffect(() => {
    if (startListening) {
      var resetTimer = setTimeout(() => {
        resetInActiveTimer();
      }, 1000);
      startVoiceRecording();
    }
    return () => {
      clearTimeout(resetTimer);
    };
  }, [startListening]);

  const { width } = useWindowSizeHook();

  console.log("state?.isGender", state?.isGender);
  const audioRef = useRef(null);

  const handlePlayPause = () => {
    if (audioRef?.current?.paused) {
      // audioRef.current.play();
    } else {
      // audioRef.current.pause();
      resetInActiveTimer();
    }
  };

  useEffect(() => {
    let handlePlayPauseTimer = setTimeout(() => {
      handlePlayPause();
    }, 10000);
    return () => {
      clearTimeout(handlePlayPauseTimer);
    };
  }, []);

  const handlePause = () => {
    // Check if the player reference exists
    if (playerRef?.current) {
      // Set the current time of the video to 0 seconds
      playerRef?.current?.seekTo(0);
    }
  };
  const handleUpload = () => {
    inputRef.current?.click();
  };
  const handleImageUpload = (e) => {
    debugger;
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);
      setPreviewImg(imageUrl);
      sendImagePrompt({
        file: selectedFile,
        messageValue: "",
      });
    }
  };

  const handlePreview = (url) => {
    setPreviewVisible(true);
  };
  const handleImageRemove = () => {
    setPreviewImg(null);
  };
  console.log("chatArray", chatArray);
  return (
    <div className="d-lg-flex">
      <Box
        width={width < 992 ? "100%" : "30rem"}
        // minWidth="30rem"
        height="100%"
        borderRadius="1.375rem"
        background="#fff"
        shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
        backdropFilter="blur(15px)"
        className="mb-5 mb-lg-0 chat-history-box me-3 py-3"
        // className="mb-5 chat-history-box me-3 py-3 d-sm-none d-lg-block"
      >
        <div>
          <Typography as="h6" align="center">
            {state?.isGender === "male" ? "Dr. William" : "Dr. Elizabeth"}
          </Typography>
        </div>
        <div className="video-container text-center w-100 mb-auto mt-2 position-relative">
          {/* {!unityLoaded && (
            <div className="position-absolute h-100 w-100 bg-white d-flex align-items-center justify-content-center">
              <Loader size={15} />
            </div>
          )}
          <Unity
            unityProvider={unityProvider}
            // style={{ width: "50%", height: "50%" }}
            style={{ width: "100%", height: "28rem" }}
          /> */}

          <div className="d-none">
            {audioBlob && (
              <audio
                autoPlay
                ref={audioRef}
                onEnded={() => {
                  setVideoPlaying(false);
                  setAudioBlob(null);
                  resetInActiveTimer();
                }}
                src={audioBlob}
              >
                Your browser does not support the audio tag.
              </audio>
            )}
          </div>
          {state?.isGender === "male" ? (
            <ReactPlayer
              url={IMAGES.MALE_DOCTOR}
              ref={playerRef}
              muted
              playing={videoPlaying}
              loop={videoPlaying}
              width={"100%"}
              height={"100%"}
              onPause={handlePause}
            />
          ) : (
            state?.isGender === "female" && (
              <ReactPlayer
                url={IMAGES.FEMALE_DOCTOR}
                ref={playerRef}
                muted
                playing={videoPlaying}
                loop={videoPlaying}
                width={"100%"}
                height={"100%"}
                onPause={handlePause}
              />
            )
          )}
        </div>
        <div className="w-100">
          <Row className="justify-content-start m-0 px-2 mb-3">
            <Col sm={12}>
              <Row>
                {state?.vitals?.map((item) => (
                  <Col sm={6}>
                    <Typography as="label" color="#636967" className="mt-2">
                      {item.title}
                    </Typography>
                    <Typography as="p" color="#4A4F4D">
                      {item.desc}
                    </Typography>
                  </Col>
                ))}
              </Row>
            </Col>
            {/* <Col sm={4}>
              <div className={`my-2 overflow-y-scroll overflow-x-hidden p-2`}>
                {file.map((file, index) => (
                  <div key={index} className="">
                    <div
                      className="cursor-pointer transform border transition duration-500 my-3 symptoms-img"
                      onMouseEnter={() => {
                        setShowEye(true);
                        setCurrentImage(index);
                      }}
                      onMouseLeave={() => setShowEye(false)}
                    >
                      <img
                        src={file}
                        alt={`preview-${index}`}
                        width={100}
                        height={90}
                        className="p-2"
                        onClick={() => handlePreview(file)}
                      />

                      <RxCross2
                        onClick={() => handleImageRemove(index)}
                        fontSize={18}
                        className="remove-icon"
                      />
                      {showEye && index === currentImage && (
                        <div
                          className="eye-icon-overlay"
                          onClick={() => handlePreview(file)}
                        >
                          <BsEye
                            className=" cursor-pointer me-1"
                            fontSize={18}
                          />{" "}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Col> */}
          </Row>
        </div>
        <div className="px-3 my-2 w-100 d-flex align-items-center justify-content-center flex-column gap-2">
          <input
            ref={inputRef}
            onChange={handleImageUpload}
            className="d-none"
            type="file"
          />
          {/* <Button
            height="3rem"
            backgroundColor="#53972C"
            width="100%"
            onClick={handleUpload}
          >
            <div>
              <Typography
                color="#fff"
                fontSize="1.5rem"
                fontWeight="500"
                className="text-center w-100"
              >
                <MdOutlineCloudUpload className="me-2" />
                Upload Symptoms
              </Typography>
            </div>
          </Button> */}
        </div>
        <div className="px-3 w-100 d-flex align-items-center justify-content-center flex-column gap-2">
          {/* <Button height="3rem" width="100%">
            <Typography
              color="#2E3130"
              fontSize="1.5rem"
              fontWeight="500"
              className="text-center w-100"
            >
              Send via Email
            </Typography>
          </Button> */}
          <Button
            height="3rem"
            width="100%"
            onClick={() => {
              navigate(ROUTER_PATH.THANK_YOU);
            }}
          >
            <Typography
              color="#2E3130"
              fontSize="1.5rem"
              fontWeight="500"
              className="text-center w-100"
            >
              End Session
            </Typography>
          </Button>
        </div>
        {/* {chatArray.map((item) => {
          if (item.type === "reply") {
            return (
              <AvatarWithLipSync
                src={IMAGES.HUMAN_CHARACTER}
                message={item.reply}
                name="John Doe"
              />
            );
          } else {
            return null; // You can handle other types of messages if needed
          }
        })} */}
        {/* <Button
          width='100%'
          height='5rem'
          backgroundColor
          border='0'
          borderRadius='0'
          className='d-flex justify-content-between align-items-center history-buttons px-4'
        >
          <Typography color='#636967' fontSize='1.5rem' fontWeight='500'>
            Chat Name
          </Typography>
          <div>
            <AiOutlineEdit color='#636967' size={26} className='mx-2' />
            <RiDeleteBinLine size={24} color="#FF0000" />
          </div>
        </Button> */}
      </Box>
      <Box
        width="100%"
        height="100%"
        maxHeight="35rem"
        maxWidth="202rem"
        borderRadius="1.375rem"
        background="linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.70) 100%)"
        shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
        backdropFilter="blur(15px)"
        className="pt-5 mb-auto mb-5 w-100"
      >
        {/* <Row
          className={`${
            width < 992
              ? "d-flex justify-content-between align-items-center px-5"
              : ""
          }`}
        >
          <Col sm={2} className="d-lg-none">
            <small className="fw-bold d-flex justify-content-center">
              {state?.isGender === "male" ? "Dr. William" : "Dr. Elizabeth"}
            </small>
            {state?.isGender === "male" ? (
              <ReactPlayer
                url={IMAGES.MALE_DOCTOR}
                muted
                playing={videoPlaying}
                loop={videoPlaying}
                width={"100px"}
                height={"100%"}
              />
            ) : (
              state?.isGender === "female" && (
                <ReactPlayer
                  url={IMAGES.FEMALE_DOCTOR}
                  muted
                  playing={videoPlaying}
                  loop={videoPlaying}
                  width={"100px"}
                  height={"100%"}
                />
              )
            )}
          </Col> */}
        <div md={8} lg={12}>
          <Typography as="h4" align="center">
            Patient Diagnosis
          </Typography>
          {/* <div className="d-lg-none">
              <Row className="justify-content-start m-0 px-2 mb-3">
                {state?.vitals?.map((item) => (
                  <Col sm={6}>
                    <Typography
                      fontSize="0.9rem"
                      fontWeight="500"
                      className="w-100"
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      fontSize="0.9rem"
                      fontWeight="300"
                      className="w-100"
                    >
                      {item.desc}
                    </Typography>
                  </Col>
                ))}
              </Row>
            </div> */}
        </div>
        {/* <Col md={2} className="d-lg-none">
            <Button
              height="3rem"
              className="d-lg-none"
              width="100%"
              onClick={() => {
                navigate(ROUTER_PATH.THANK_YOU);
              }}
            >
              <Typography
                color="#2E3130"
                fontSize="1.5rem"
                fontWeight="500"
                className="text-center w-100"
              >
                End
              </Typography>
            </Button>
          </Col>
        </Row> */}

        <Box
          width="100%"
          height="calc(100vh - 28.5rem)"
          minHeight="450px"
          background="transparent"
          className="px-sm-1 px-lg-5  mt-4"
          overflowY="auto"
          id="chatBox"
        >
          {chatArray?.map((item, index) => {
            return item?.type === "reply" ? (
              <ReplyTemplate
                reply={item?.reply}
                loader={chat?.GET_WELCOME_MESSAGE_IS_LOADING}
                getAudio={getAudio}
                resetInActiveTimer={resetInActiveTimer}
                // speakMessage={speakMessage}
                isGender={state?.isGender}
              />
            ) : item?.type === "imagePrompt" ? (
              <>
                <div className="d-flex justify-content-end">
                  <div className="bg-white p-3  border chat-symptoms-img">
                    <img
                      src={item.file}
                      alt={`preview-${index}`}
                      // width={300}
                      height={120}
                      className=""
                    />
                  </div>
                </div>
                {/* <MessageTemplate message={item?.message} /> */}
              </>
            ) : (
              <MessageTemplate message={item?.message} />
            );
          })}
          {(chat?.GET_WELCOME_MESSAGE_IS_LOADING ||
            chat?.GET_PROMPT_RESPONSE_IS_LOADING) && <Loader size={10} />}
        </Box>

        <Box
          width="100%"
          background="var(--secondary-150, #F4FBEA)"
          borderRadius="0.75rem 0.75rem 0 0"
          className="d-flex flex-wrap justify-content-between py-3 px-sm-1 px-lg-5 "
        >
          <div className="d-flex align-items-center justify-content-center bg-red-700">
            {startListening ? (
              <Box
                width="3rem"
                height="3rem"
                // isTooltip={"Press to stop"}
                background="#ED6A58"
                className="rounded-circle d-flex justify-content-center align-items-center mx-3 cursor-pointer"
                onClick={() => {
                  setStartListening(false);
                  // setSentVoice(true);
                  resetInActiveTimer();
                  stopRecordingVoice();
                }}
              >
                <BsFillStopFill color="white" size={width > 1360 ? 24 : 15} />
              </Box>
            ) : (
              <Box
                width="3rem"
                height="3rem"
                isTooltip={videoPlaying ? false : true}
                background={videoPlaying ? "#898989" : "#53972C"}
                className={`rounded-circle d-flex justify-content-center align-items-center mx-3 mt-1 ${
                  videoPlaying ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                onClick={startVoiceCommand}
              >
                <BsFillMicMuteFill
                  color="#ffffff"
                  size={width > 1360 ? 24 : 15}
                />
              </Box>
            )}
            {startListening && <Loader size={10} />}
            {!startListening && (
              <b>
                <small>
                  If you prefer to speak to the doctor, rather than type, please
                  press the mic button to speak into it.
                </small>
              </b>
            )}
            {/* <ReactMic
              // className="abcd"
              record={startListening}
              onData={onData}
              onStop={onData}
              mimeType="audio/wav"
              height={0}
              width={0}
            /> */}
            {/* {!isPermissionGranted && (
              <Typography as="label" color="#ED6A58">
                Please allow mic permissions to use feature
              </Typography>
            )} */}
          </div>
        </Box>
        <div className="scroll-hide">
          <div
            className="upload-img"
            // className={`upload-img
            // ${previewImg ? "pt-3" : ""}
            // `}
          >
            {/* {previewImg && (
              <div
                className="cursor-pointer transform border transition duration-500  symptoms-img"
                onMouseEnter={() => {
                  setShowEye(true);
                }}
                onMouseLeave={() => setShowEye(false)}
              >
                <img
                  src={previewImg}
                  alt={`previewImag`}
                  height={60}
                  width={100}
                  className="p-2"
                  onClick={() => handlePreview()}
                />

                <RxCross2
                  onClick={() => handleImageRemove()}
                  fontSize={18}
                  className="remove-icon"
                />
                {showEye && (
                  <div
                    className="eye-icon-overlay"
                    onClick={() => handlePreview(file)}
                  >
                    <BsEye className=" cursor-pointer me-1" fontSize={18} />{" "}
                  </div>
                )}
              </div>
            )}
            {previewImg && (
              <div className="border-bottom border-light-subtle mx-4 mt-3 " />
            )} */}

            <div className=" input-field ">
              <div>
                <RiImageAddFill
                  className={`me-2`}
                  cursor={` ${videoPlaying || file ? "" : "pointer"}`}
                  fontSize={22}
                  color={` ${videoPlaying || file ? "#888888" : "#53972C"}`}
                  onClick={() => !videoPlaying && !file && handleUpload()}
                />
              </div>

              <GenericInput
                placeholder="Enter your message"
                className="scroll-hide-content"
                fontSize="1.25rem"
                autoFocus={true}
                width="100%"
                minHeight="100%"
                value={textareaValue}
                onChange={(e) => handleTextareaChange(e)}
                onKeyPress={(e) => handleKeyPress(e)}
                onPressEnter
                as="textarea"
                border="none"
                disabled={
                  chat?.GET_WELCOME_MESSAGE_IS_LOADING ||
                  chat?.GET_PROMPT_RESPONSE_IS_LOADING
                }
                borderRadius="0 0 1.375rem 1.375rem"
              />
            </div>
          </div>
        </div>
      </Box>

      {confirmationModalShow && (
        <ConfirmationModal
          show={confirmationModalShow}
          onHide={() => setConfirmationModalShow(false)}
          size="md"
          title={<Typography as="h6">Save Chat in History</Typography>}
          successAction={handleSaveChat}
          loader={chat?.SAVE_CHAT_HISTORY_IS_LOADING}
        >
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <Typography as="p" size="1.5rem">
                Chat Name
              </Typography>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name of Chat"
              onChange={(e) => {
                setChatName(e.target.value);
              }}
              autoFocus
            />
          </Form.Group>
        </ConfirmationModal>
      )}

      <ConfirmationModal
        show={inActiveModal}
        onHide={() => setInActiveModal(false)}
        size="md"
        hideFooter={true}
      >
        <div className="text-center">
          <h4 className="mb-4">Hey, are you still with us?</h4>
          <Button
            onClick={() => {
              setInActiveModal(false);
              resetInActiveTimer();
            }}
            className="px-4 py-2"
            variant="success"
          >
            Yes
          </Button>
        </div>
      </ConfirmationModal>
      <GenericModal
        show={previewVisible}
        onHide={() => setPreviewVisible(false)}
      >
        <img alt="Preview" style={{ width: "100%" }} src={previewImg} />
      </GenericModal>
    </div>
  );
}
