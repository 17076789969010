import {
  getAudioFromTextApi,
  getChatHistoryApi,
  getPromptResponseApi,
  getWelcomeMessageApi,
  saveUserChatApi,
} from "../api/chat";

export function getWelcomeMessage({ id }) {
  return (dispatch) => {
    dispatch({
      type: "GET_WELCOME_MESSAGE_REQUEST",
    });
    getWelcomeMessageApi(id).then(
      (response) => {
        dispatch({
          type: "GET_WELCOME_MESSAGE_SUCCESS",
          payload: response.data,
        });
      },
      (error) => {
        dispatch({
          type: "GET_WELCOME_MESSAGE_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}

export function getPromptResponse(payload, userId, id, file) {
  debugger;
  return (dispatch) => {
    dispatch({
      type: "GET_PROMPT_RESPONSE_REQUEST",
    });
    getPromptResponseApi(payload, userId, id, file).then(
      (response) => {
        dispatch({
          type: "GET_PROMPT_RESPONSE_SUCCESS",
          payload: response.data,
        });
      },
      (error) => {
        dispatch({
          type: "GET_PROMPT_RESPONSE_ERROR",
          payload: error.message,
        });
      }
    );
  };
}

export function getAudioFromText() {
  return (dispatch) => {
    dispatch({
      type: "GET_AUDIO_FROM_TEXT_REQUEST",
    });

    getAudioFromTextApi().then(
      // getAudioFromTextApi(payload, userId, id).then(
      (response) => {
        let bytes = new Uint8Array(response.data.length);

        for (let i = 0; i < bytes.length; i++) {
          bytes[i] = response.data.charCodeAt(i);
        }
        let blob = new Blob([bytes], { type: "audio/mp3" });

        dispatch({
          type: "GET_AUDIO_FROM_TEXT_SUCCESS",
          payload: blob,
          // payload: response.data,
        });
      },
      (error) => {
        dispatch({
          type: "GET_AUDIO_FROM_TEXT_ERROR",
          payload: error.message,
        });
      }
    );
  };
}

export function saveUserChat(chatName, user_id, Toaster, moveToNext) {
  return (dispatch) => {
    dispatch({
      type: "SAVE_CHAT_HISTORY_REQUEST",
    });
    saveUserChatApi(chatName, user_id).then(
      (response) => {
        if (response.status === 200) {
          dispatch({
            type: "SAVE_CHAT_HISTORY_SUCCESS",
            payload: response.data,
          });
          if (Toaster)
            Toaster({
              message: `Chat save Successfully`,
              type: "success",
            });
          if (moveToNext) moveToNext();
        }
      },
      (error) => {
        dispatch({
          type: "SAVE_CHAT_HISTORY_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}

export function getUserChatHistory(userId) {
  console.log("user id in action: ", userId);
  return (dispatch) => {
    dispatch({
      type: "GET_CHAT_HISTORY_REQUEST",
    });
    getChatHistoryApi(userId).then(
      (response) => {
        console.log("get history response: ", response);
        dispatch({
          type: "GET_CHAT_HISTORY_SUCCESS",
          payload: response.data,
        });
      },
      (error) => {
        dispatch({
          type: "GET_CHAT_HISTORY_IS_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}
