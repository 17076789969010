import PropTypes from "prop-types";

export default function GenericInput({ as, ...props }) {
  const InputStyles = {
    width: props.width,
    height: props.height,
    maxHeight: props.maxHeight,
    minHeight: props.minHeight,
    border: props.border,
    color: props.border,
    borderRadius: props.borderRadius,
    fontSize: props.fontSize,
  };

  const autoResize = (element) => {
    element.style.height = "auto";
    element.style.height = `${element.scrollHeight}px`;
  };

  if (as === "textarea") {
    return (
      <textarea
        {...props}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onInput={(e) => {
          props.onInput && props.onInput(e);
          autoResize(e.target);
        }}
        contentEditable="true"
        style={InputStyles}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
      />
    );
  } else {
    return (
      <input
        {...props}
        name={props.name}
        value={props.value}
        type={props.type}
        placeholder={props.placeholder}
        onChange={props.onChange}
        style={InputStyles}
        contentEditable="true"
      />
    );
  }
}

GenericInput.propTypes = {
  as: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.oneOf(["text", "password"]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  border: PropTypes.string,
  color: PropTypes.string,
  borderRadius: PropTypes.string,
  fontSize: PropTypes.string,
  autoFocus: PropTypes.bool,
};

GenericInput.defaultProps = {
  as: "",
  name: "",
  value: null,
  type: "text",
  placeholder: "Enter Placeholder",
  onChange: () => {},
  width: "",
  height: "",
  minHeight: "60px",
  maxHeight: "",
  border: "",
  borderRadius: "",
  color: "var(--black-900, #191A1A)",
  fontSize: "1rem",
  autoFocus: false,
};
